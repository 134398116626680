import React from 'react';
import { NotificationsContainerProps } from './NotificationsContainer.types';
import withStyles from '../theme/withStyles';
import { NotificationProps } from './Notification.types';

const styles = theme => ({
    'notifications-container': {
        position: 'fixed',
        right: '1rem',
        top: '1rem'
    }
});

const NotificationsContainer: React.FC<NotificationsContainerProps> = (props) => {
    let {
        notifications,
        classes
    } = props;

    return (
        <div className={classes['notifications-container']}>
            {notifications.map((notification, index) => (
                <div key={index}>
                    {notification.element}
                </div>
            ))}
        </div>
    );
};

export default withStyles<NotificationProps>(styles)(NotificationsContainer);