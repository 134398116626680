import { colors } from '../colors';

export const badgeTheme = {
    colors: {
        border: colors.white,
        primary: colors.primary,
        secondary: colors.secondary,
        success: colors.success,
        danger: colors.danger
    },
    sizes: {
        dot: '0.6rem',
        regular: '1.25rem'
    }
};