import React, { cloneElement, useEffect, useState } from 'react';
import classnames from 'classnames';

import withStyles from '../theme/withStyles';

import { InputProps } from './Input.types';

const styles = theme => ({
    input: {
        outline: 'none',
        padding: '0.5rem 0.8rem',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        fontSize: '0.8rem',
        margin: '0.2rem 0'
    },
    'input-root': {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: theme.fontFamily,
        '& p': {
            margin: 0
        },
        '& > label': {
            fontSize: '0.8rem'
        }
    },
    'input-regular': {
        border: `1px solid ${theme.input.colors.primary}`
    },
    'input-square': {
        borderRadius: 0
    },
    'input-comment': {
        fontSize: '0.75rem'
    },
    'input-error': {
        border: `1px solid ${theme.input.colors.danger}`
    },
    'input-error-container': {
        height: '1rem',
        fontSize: '0.75rem',
        color: theme.input.colors.danger
    }
});

const Input: React.FC<InputProps> = (props) => {
    const {
        id,
        value = '',
        getInitialValue,
        onChange,
        label,
        placeholder,
        type = 'text',
        disabled,
        multiline,
        mask,
        prefix,
        error,
        comment,
        classes,
        testId
    } = props;

    const getValue = (id: string) => {
        let value = '';
        if (getInitialValue) {
            value = getInitialValue(id);
        }

        if (prefix && !value) {
            value = prefix;
        }

        return value;
    };

    let [ state, setState ] = useState(getValue(id));

    /*useEffect(() => {
        if (value) {
            setState(value);
        }
    }, [ value ]);*/

    const inputElementClass = classnames(
        classes.input,
        classes['input-regular'],
        { 'vui-input-error': error }
    );


    const applyMask = (value: string, selectionStart: number): string => {
        let fullMask = prefix + mask;

        if (selectionStart < prefix.length || value.length > fullMask.length) {
            return value.slice(0, selectionStart - 1) + value.slice(selectionStart);
        }

        let maskChar = fullMask[selectionStart - 1];
        const numeric = /^[0-9]+$/;

        if (!value[selectionStart - 1].match(numeric)) {
            return value.slice(0, selectionStart - 1) + value.slice(selectionStart);
        }

        if (!maskChar.match(numeric)) {
            value = value.slice(0, value.length - 1) + maskChar + value[value.length - 1];
            return applyMask(value, ++selectionStart);
        }

        return value;
    };

    const inputElementProps = {
        type: type,
        className: inputElementClass,
        disabled: disabled,
        placeholder: placeholder,
        value: state,
        onChange: (e) => {
            let value = e.target.value;

            if (prefix && value.length < prefix.length) {
                return;
            }

            if (mask) {
                value = applyMask(value, e.target.selectionStart);
            }

            setState(value);
            if (onChange) {
                onChange(id, value);
            }
        }
    };

    const inputElement = multiline ? (
        <textarea />
    ) : (
        <input />
    );

    const errorComponent = (
        <div className={classes['input-error-container']}>
            <p>{error}</p>
        </div>
    );

    const resultContainer = (
        <div>
            <label>{label}</label>

            {cloneElement(
                inputElement,
                inputElementProps
            )}

            {comment && <p className={classes['input-comment']}>{comment}</p>}

            {errorComponent}
        </div>
    );

    const resultProps = {
        className: classes['input-root'],
        'data-testid': testId
    };

    return cloneElement(
        resultContainer,
        resultProps
    );
};

export default withStyles<InputProps>(styles, { name: 'Input', input: true })(Input);