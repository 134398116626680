export const colors = {
    transparent: 'transparent',
    primary: '#4059AD',
    primaryDark: '#334798',
    secondary: '#D7DAD9',
    secondaryDark: '#8F9291',
    success: '#58A361',
    danger: '#F44336',
    warn: '#F4B942',
    disabled: '#D3D3D3',
    disabledText: '#7E7E7E',
    black: '#222222',
    deepBlack: '#000000',
    white: '#FFFFFF'
};