import React from 'react';
import { TagsInputItemProps } from './TagsInputItem.types';
import withStyles from '../theme/withStyles';
import EraseIcon from './erase.icon';
import IconButton from '../icon.button/IconButton';

const styles = theme => ({
    'tags-input-item': {
        display: 'flex',
        padding: '0.2rem 0.4rem',
        backgroundColor: '#eee',
        borderRadius: '9999px',
        marginRight: '0.2rem',
        fontSize: '0.75rem',
        fontFamily: theme.fontFamily
    }
});

const TagsInputItem: React.FC<TagsInputItemProps> = (props) => {
    let {
        value,
        onRemove,
        removeIcon = true,
        nodeRef,
        children,
        classes
    } = props;

    const onTagRemove = (e) => {
        if (onRemove) {
            onRemove(value, e);
        }
    };

    return (
        <div className={classes['tags-input-item']}
             ref={nodeRef}
        >
            {children}

            {removeIcon &&
                <IconButton icon={EraseIcon}
                            size={'s'}
                            styleType={'ghost'}
                            onClick={onTagRemove}
                />
            }
        </div>
    );
};

export default withStyles<TagsInputItemProps>(styles, { name: 'tagInputItem' })(TagsInputItem);