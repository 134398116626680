import React, { cloneElement, useEffect, useReducer, useRef } from 'react';
import classnames from 'classnames';

import withStyles from '../theme/withStyles';

import { SelectProps } from './Select.types';
import TagsInput from '../tags.input/TagsInput';
import TagsInputItem from '../tags.input/TagsInputItem';

const SelectActions = {
    SelectOption: 'select_option',
    OpenOptions: 'open_options',
    CloseOptions: 'close_options',
    RemoveOption: 'remove_option',
    OptionsFiltered: 'options_filtered'
};

const SelectReducer = (state, action) => {
    switch (action.type) {
        case SelectActions.OpenOptions: {
            if (state.optionsVisible && state.filter) {
                return state;
            }

            return {
                ...state,
                focus: !state.focus,
                optionsVisible: !state.optionsVisible
            };
        }
        case SelectActions.CloseOptions:
            return {
                ...state,
                focus: false,
                optionsVisible: false
            };
        case SelectActions.RemoveOption: {
            let index = state.selected.indexOf(action.payload);
            if (index > -1) {
                state.selected.splice(index, 1);
            }
            return {
                ...state
            }
        }
        case SelectActions.SelectOption: {
            if (state.multiple) {
                let index = state.selected.indexOf(action.payload);
                if (index > -1) {
                    state.selected.splice(index, 1);
                } else {
                    state.selected.push(action.payload);
                }
            } else {
                state.selected = action.payload;
            }

            return {
                ...state,
                focus: false,
                optionsVisible: state.multiple
            }
        }
        case SelectActions.OptionsFiltered: {
            return {
                ...state,
                options: action.payload
            }
        }
        default:
            return state;
    }
};

const styles = theme => ({
    select: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '1.6rem',
        backgroundColor: 'transparent',
        outline: 'none',
        padding: '0.2rem 1rem',
        borderRadius: '4px',
        border: `1px solid ${theme.select.colors.primary}`,
        cursor: 'pointer'
    },
    'select-container': {
        fontFamily: theme.fontFamily,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.8rem'
    },
    'select-option': {
        cursor: 'pointer',
        padding: '0.5rem',
        fontSize: '0.8rem',
        '&:hover': {
            backgroundColor: '#e2e2e2'
        }
    },
    'select-option-active': {
        backgroundColor: '#e2e2e2',
        cursor: 'default'
    },
    'select-options-container': {
        position: 'absolute',
        top: '100%',
        width: '100%',
        border: '1px solid #eee',
        backgroundColor: '#fff',
        zIndex: 100
    },
    'select-options-values': {
        padding: '0.2rem'
    },
    'select-options': { },
    'select-actions': {
        position: 'absolute',
        right: '0.75rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    }
});

const Tags = ({ state, onRemove }) => (
    <TagsInput isExpanded={state.multiple && state.optionsVisible}
               onRemove={onRemove}
    >
        {
            state.selected.map(selected => (
                <TagsInputItem key={selected.value} value={selected}>
                    {selected.toString()}
                </TagsInputItem>
            ))
        }
    </TagsInput>
);

const EVENT = 'mousedown';

const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref || !ref.current || ref.current.contains(event.target)) {
                return;
            }
            callback(event);
        };
        document.addEventListener(EVENT, listener);
        return () => {
            document.removeEventListener(EVENT, listener);
        };
    }, [ref, callback]);
};


const SelectOptions = ({ visible, state, dispatch, options, selected, onRemove, classes }) => {
    /*useEffect(() => {
        const outsideClickListener = (e) => {
            let target = e.target;
            let close = !e.target.id;
            while (target.parentElement) {
                if (target.parentElement.id === 'vui-select' || target.parentElement.id === 'vui-select-options') {
                    close = false;
                }

                target = target.parentElement;
            }

            if (close) {
                dispatch({ type: SelectActions.CloseOptions });
            }
        };

        if (visible) {
            document.addEventListener('click', outsideClickListener);
        }

        return () => document.removeEventListener('click', outsideClickListener);
    }, [ visible ]);*/

    let optionsRef = useRef(null);
    useClickOutside(optionsRef, () => dispatch({ type: SelectActions.CloseOptions }));


    if (!visible) {
        return null;
    }

    const select = (option) => {
        dispatch({ type: SelectActions.SelectOption, payload: option });
    };

    const OptionComponent = ({ id, option }) => {
        const isOptionActive = (option) => {
            if (state.multiple) {
                return selected.indexOf(option) > -1;
            }

            return selected === option;
        };

        let activeOptionClass = isOptionActive(option) ? classes['select-option-active'].toString() : '';

        return (
            <div id={id}
                 className={classnames(
                     classes['select-option'],
                     activeOptionClass
                 )}
                 onClick={() => select(option)}
            >
                {option.toString()}
            </div>
        );
    };

    return (
        <div className={classes['select-options-container']}
             ref={optionsRef}
        >
            {state.multiple && state.filter && <div className={classes['select-options-values']}><Tags state={state} onRemove={onRemove}/></div>}

            <div className={classes['select-options']} id={'vui-select-options'}>
                {
                    options.map(option => (
                        <OptionComponent key={option.value}
                                         id={option.value}
                                         option={option}
                        />
                    ))
                }
            </div>
        </div>
    );
};

const Select: React.FC<SelectProps> = (props) => {
    let {
        id,
        label,
        placeholder,
        options,
        filter,
        multiple,
        onChange,
        classes
    } = props;

    let [ state, dispatch ] = useReducer(SelectReducer, {
        optionsVisible: false,
        multiple: multiple,
        selected: null,
        focus: false,
        filter: filter,
        options: options
    });

    useEffect(() => {
        if (onChange) {
            let value = null;
            if (multiple) {
                value = state.selected;
            } else if (state.selected) {
                value = state.selected;
            }
            onChange(id, value);
        }
    }, [ state.selected ]);

    const onClick = () => {
        dispatch({ type: SelectActions.OpenOptions })
    };

    const bouncing = (() => {
        let timer = null;
        return (callback, delay) => {
            clearTimeout(timer);
            timer = setTimeout(callback, delay)
        }
    })();

    const onInput = (e) => {
        if (filter) {
            e.persist();
            const find = async () => {
                let options = await filter(e.target.value);
                dispatch({ type: SelectActions.OptionsFiltered, payload: options });
            };

            bouncing(find, 500);
        }
    };

    const selectClassName = classnames(
        classes.select,
        { [classes['select-opened']]: state.multiple && state.optionsVisible }
    );

    const selectElement = filter && state.optionsVisible ? (
        <input/>
    ) : (
        <div/>
    );

    const selectProps = {
        className: selectClassName,
        onClick: onClick,
        id: 'vui-select',
        autoFocus: true,
        onChange: onInput
    };

    const onRemove = (value, e) => {
        e.stopPropagation();
        dispatch({ type: SelectActions.RemoveOption, payload: value });
    };

    const selectChildren = filter && state.optionsVisible ? null : (
        <div>
            {(!state.selected || state.selected.length === 0) && placeholder}

            {state.multiple && <TagsInput isExpanded={state.multiple && state.optionsVisible}
                       onRemove={onRemove}
            >
                {
                    state.selected.map(selected => (
                        <TagsInputItem key={selected.value} value={selected}>
                            {selected.toString()}
                        </TagsInputItem>
                    ))
                }
            </TagsInput>}

            {!state.multiple && state.selected && <span>{state.selected.toString()}</span>}
        </div>
    );

    return (
        <div className={classes['select-container']}>
            <label>{label}</label>

            {cloneElement(selectElement, selectProps, selectChildren)}

            <SelectOptions options={state.options}
                           visible={state.optionsVisible}
                           classes={classes}
                           selected={state.selected}
                           state={state}
                           dispatch={dispatch}
                           onRemove={onRemove}
            />
        </div>
    )
};

export default withStyles<SelectProps>(styles, { input: true })(Select);