import React from 'react';
import classnames from 'classnames';

import { TabItemProps } from './TabItem.types';
import withStyles from '../theme/withStyles';

const styles = theme => ({
    'tab-item': {
        padding: '0.5rem',
        cursor: 'pointer',
        fontSize: '0.8rem',
        '&:hover': {
            color: theme.tabs.colors.selected
        }
    },
    'tab-item-selected': {
        cursor: 'auto',
        color: theme.tabs.colors.selected,
        borderBottom: `2px solid ${theme.tabs.colors.selected}`
    }
});

const TabItem: React.FC<TabItemProps> = (props) => {
    let {
        value,
        onClick,
        isSelected,
        children,
        classes
    } = props;

    let tabItemClassName = classnames(
        classes['tab-item'],
        { [classes['tab-item-selected']]: isSelected }
    );

    const onTabClick = () => {
        if (onClick) {
            onClick(value);
        }
    };

    return (
        <div className={tabItemClassName}
             onClick={onTabClick}
        >
            {children}
        </div>
    );
};

export default withStyles<TabItemProps>(styles, { name: 'TabItem', input: false })(TabItem);