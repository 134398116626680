import React from 'react';

import { SvgIconProps } from './SvgIcon.types';
import withStyles from '../theme/withStyles';

const styles = theme => ({

});

const SvgIcon: React.FC<SvgIconProps> = ({
    fill = 'currentColor',
    children,
    size = '1rem',
    style = {},
    viewBox = '0 0 24 24',
    className
}) => (
    <svg viewBox={viewBox}
         fill={fill}
         height={size}
         width={size}
         style={style}
         className={className}
    >
        {children}
    </svg>
);

export default withStyles<SvgIconProps>(styles)(SvgIcon);