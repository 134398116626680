import React, { useState } from 'react';
import classnames from 'classnames';

import withStyles from '../theme/withStyles';

import { ToggleProps } from './Toggle.types';

const styles = theme => ({
    'toggle-container': {
        display: 'flex',
        alignItems: 'center',
        fontFamily: theme.fontFamily
    },
    'toggle-label': {
        marginLeft: '0.5rem',
        fontSize: '0.8rem'
    },
    toggle: {
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '9999px',
        backgroundColor: theme.toggle.normal.background,
        width: '2.4rem',
        height: '1.4rem',
    },
    'toggle-checked': {
        backgroundColor: theme.toggle.checked.background
    },
    'toggle-mark-checked': {
        transform: 'translateX(100%)',
        transition: 'all 300ms'
    },
    'toggle-mark': {
        width: '1rem',
        height: '1rem',
        borderRadius: '50%',
        backgroundColor: '#fff',
        position: 'absolute',
        left: '0.2rem',
        top: '0.2rem',
        transition: 'all 300ms'
    }
});

const Toggle: React.FC<ToggleProps> = (props) => {
    let {
        checked,
        label,
        onChange,
        classes
    } = props;

    let [ toggleChecked, setToggleChecked ] = useState(checked || false);

    const toggleClassName = classnames(
        classes.toggle,
        { [classes['toggle-checked']]: toggleChecked }
    );

    const toggleMarkClassName = classnames(
        classes['toggle-mark'],
        { [classes['toggle-mark-checked']]: toggleChecked }
    );

    const onClick = () => {
        setToggleChecked(!toggleChecked);
        if (onChange) {
            onChange(!toggleChecked);
        }
    };

    return (
        <div className={classes['toggle-container']}>
            <div className={toggleClassName} onClick={onClick}>
                <div className={toggleMarkClassName}/>
            </div>

            <span className={classes['toggle-label']}>{label}</span>
        </div>
    );
};

export default withStyles<ToggleProps>(styles)(Toggle);