import { colors } from '../colors';

export const sliderTheme = {
    sizes: {
        m: '1rem'
    },
    thumb: {
        colors: {
            primary: colors.primary
        }
    }
};