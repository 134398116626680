import React, { Children, cloneElement, useEffect, useReducer, useRef } from 'react';

import withStyles from '../theme/withStyles';

import { TagsInputProps } from './TagsInput.types';
import TagsInputItem from './TagsInputItem';

const styles = theme => ({
    'tags-input': {
        display: 'flex',
        flexWrap: 'wrap',
    },
    'tags-input-container': {
        display: 'block'
    }
});

const TagsInputActions = {
    ChildrenLoaded: 'children_loaded',
    TagRefLoaded: 'tag_ref_loaded',
    VisibleTagsUpdated: 'visible_tags_updated'
};

const TagsInputReducer = (state, action) => {
    switch (action.type) {
        case TagsInputActions.ChildrenLoaded: {
            return {
                ...state,
                tags: action.payload,
                visibleTags: action.payload
            };
        }
        case TagsInputActions.TagRefLoaded: {
            state.tagRefs[action.payload.index] = action.payload.ref;
            return {
                ...state
            };
        }
        case TagsInputActions.VisibleTagsUpdated: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
};

const TagsInput: React.FC<TagsInputProps> = (props) => {
    let {
        onRemove,
        isExpanded,
        children,
        classes
    } = props;

    const onTagRemove = (value, e) => {
        if (onRemove) {
            onRemove(value, e);
        }
    };

    let containerRef = useRef(null);
    let moreRef = useRef(null);

    let [ state, dispatch ] = useReducer(TagsInputReducer, {
        tags: [],
        visibleTags: [],
        tagRefs: [],
        moreCount: 0,
        moreVisible: false
    });

    useEffect(() => {
        let tags = Children.toArray(children).map((child: any, index: number) => {
            return cloneElement(child, {
                nodeRef: ref => saveItemsRefs(ref, index),
                onRemove: onTagRemove
            });
        });

        dispatch({ type: TagsInputActions.ChildrenLoaded, payload: tags });

    }, [ children ]);

    const saveItemsRefs = (ref, index) => {
        dispatch({ type: TagsInputActions.TagRefLoaded, payload: { index: index, ref: ref } })
    };

    const updateTags = () => {
        if (state.tagRefs.length === 0) {
            return;
        }

        if (isExpanded) {
            //setVisibleTags(tags);
            return;
        }

        let containerWidth = containerRef.current.getBoundingClientRect().width;

        let width = 0;
        let index = 0;

        while (index < state.tagRefs.length) {
            if (state.tagRefs[index]) {
                const tagWidth = Math.ceil(state.tagRefs[index].getBoundingClientRect().width);
                if (width + tagWidth >= containerWidth) {
                    break;
                }

                width += tagWidth + 4;
            }

            ++index;
        }

        let moreCount = state.tags.length - index;
        dispatch({
            type: TagsInputActions.VisibleTagsUpdated,
            payload: {
                moreCount: moreCount,
                moreVisible: moreCount > 0,
                visibleTags: state.tags.slice(0, index)
            }
        });
    };

    useEffect(() => {
        if (containerRef) {
            updateTags();
        }
    }, [ state.tagRefs.length ]);

    return (
        <div ref={containerRef}
             className={classes['tags-input-container']}
        >
            <div className={classes['tags-input']}
            >
                {state.visibleTags}

                {state.moreVisible && !isExpanded &&
                    (<div ref={moreRef}>
                        <TagsInputItem removeIcon={false}>
                            +{state.moreCount}
                        </TagsInputItem>
                    </div>)
                }
            </div>
        </div>
    );
};

export default withStyles<TagsInputProps>(styles)(TagsInput);