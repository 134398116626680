import React from 'react';
import classnames from 'classnames';

import { BadgeProps } from './Badge.types';

import withStyles from '../theme/withStyles';

const styles = theme => ({
    'badge-container': {
        position: 'relative',
        display: 'inline-block'
    },
    badge: {

        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '--pos-x': 'calc(50% + 50% / 1.4142135623730950488016887242097 - var(--offset-x))',
        '--pos-y': 'calc(50% + 50% / 1.4142135623730950488016887242097 - var(--offset-y))'
    },
    'badge-inline': {
        position: 'inline-block'
    },
    'badge-primary': {
        backgroundColor: theme.badge.colors.primary
    },
    'badge-secondary': {
        backgroundColor: theme.badge.colors.secondary
    },
    'badge-success': {
        backgroundColor: theme.badge.colors.success
    },
    'badge-danger': {
        backgroundColor: theme.badge.colors.danger
    },
    'badge-regular': {
        height: theme.badge.sizes.regular,
        minWidth: theme.badge.sizes.regular,
        '--offset-x': '12px',
        '--offset-y': '12px'
    },
    'badge-dot': {
        height: theme.badge.sizes.dot,
        width: theme.badge.sizes.dot,
        '--offset-x': '5px',
        '--offset-y': '5px'
    },
    'badge-content': {
        fontFamily: theme.fontFamily,
        color: '#fff',
        fontSize: '0.7rem',
        lineHeight: '0.5rem'
    },
    'badge-border': {
        border: `1px solid ${theme.badge.colors.border}`,
        boxSizing: 'border-box'
    },
    'badge-over-square': {
        '--pos-x': 'calc(100% - var(--offset-x))',
        '--pos-y': 'calc(100% - var(--offset-y))'
    },
    'badge-overlap': {
        '--offset': '10px'
    },
    'badge-round': {
        borderRadius: '50%'
    },
    'badge-square': {
        borderRadius: '4px'
    },
    'badge-top-left': {
        top: 'auto',
        right: 'var(--pos-x)',
        bottom: 'var(--pos-y)',
        left: 'auto'
    },
    'badge-top-right': {
        top: 'auto',
        right: 'auto',
        bottom: 'var(--pos-y)',
        left: 'var(--pos-x)'
    },
    'badge-bottom-left': {
        top: 'var(--pos-y)',
        right: 'var(--pos-x)',
        bottom: 'auto',
        left: 'auto'
    },
    'badge-bottom-right': {
        top: 'var(--pos-y)',
        right: 'auto',
        bottom: 'auto',
        left: 'var(--pos-x)'
    }
});

const Badge: React.FC<BadgeProps> = (props) => {
    let {
        content = null,
        dot = false,
        color = 'primary',
        hidden = false,
        withBorder = false,
        overSquare = false,
        overlap = false,
        shape = 'round',
        position = 'bottom-right',
        offsetX,
        offsetY,
        className,
        style,
        containerClassName,
        containerStyle,
        contentClassName,
        contentStyle,
        children,
        classes,
        testId
    } = props;

    let badgeClassName = classnames(
        classes.badge,
        classes[`badge-${position}`],
        classes[`badge-${shape}`],
        { [classes[`badge-${color}`]]: !color.startsWith('#') },
        { [classes['badge-overlap']]: overlap },
        { [classes['badge-border']]: withBorder },
        { [classes['badge-over-square']]: overSquare },
        { [classes['badge-regular']]: !dot && content },
        { [classes['badge-dot']]: dot || !content },
        className
    );

    let badgeContainerClassName = classnames(
        classes['badge-container'],
        containerClassName
    );

    let badgeContentClassName = classnames(
        classes['badge-content'],
        contentClassName
    );

    let badgeStyle = {
        ...style,
        backgroundColor: color.startsWith('#') ? color : null,
        '--offset-x': offsetX,
        '--offset-y': offsetY
    };

    const badgeProps = {
        'data-testid': testId
    };

    return (
        <div className={badgeContainerClassName}
             style={containerStyle}
        >
            {children}

            {
                !hidden &&
                <div className={badgeClassName}
                     style={badgeStyle}
                     {...badgeProps}
                >
                    {!dot && <span className={badgeContentClassName} style={contentStyle}>{content}</span>}
                </div>
            }
        </div>
    );
};

export default withStyles<BadgeProps>(styles)(Badge);