import React, { cloneElement, isValidElement } from 'react';
import classnames from 'classnames';

import { AvatarProps } from './Avatar.types';

import withStyles from '../theme/withStyles';

const styles = theme => ({
    avatar: {
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        textDecoration: 'none',
        color: theme.avatar.colors.char,
        fontFamily: theme.fontFamily
    },
    'avatar-transparent': {
        color: theme.avatar.colors.transparent.char,
        border: `1px solid ${theme.avatar.colors.border}`
    },
    'avatar-link': {
        '&:hover': {
            boxShadow: '-2px 2px 10px 0 rgba(102, 116, 166, 0.2)'
        }
    },
    'avatar-active': {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '-2px 2px 10px 0 rgba(102, 116, 166, 0.2)'
        }
    },
    'avatar-image': {
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    'avatar-s': {
        height: theme.avatar.sizes.small,
        width: theme.avatar.sizes.small
    },
    'avatar-m': {
        height: theme.avatar.sizes.medium,
        width: theme.avatar.sizes.medium
    },
    'avatar-l': {
        height: theme.avatar.sizes.large,
        width: theme.avatar.sizes.large
    },
    'avatar-circle': {
        borderRadius: '50%'
    },
    'avatar-rounded': {
        borderRadius: '4px'
    },
    'avatar-square': {
        borderRadius: 0
    }
});

const Avatar: React.FC<AvatarProps> = (props) => {
    let {
        char,
        src,
        shape = 'circle',
        size = 'm',
        backgroundColor,
        onClick,
        container,
        href,
        target,
        rel,
        className,
        style,
        imageClassName,
        imageStyle,
        charClassName,
        charStyle,
        classes,
        testId
    } = props;

    const containerClassName = classnames(
        classes.avatar,
        classes[`avatar-${size}`],
        classes[`avatar-${shape}`],
        { [classes['avatar-transparent']]: !backgroundColor && !src },
        { [classes['avatar-link']]: href },
        { [classes['avatar-active']]: onClick },
        className
    );

    const containerElement = isValidElement(container) ? (
        container
    ) : href ? (
        <a href={href} target={target} rel={rel} />
    ) : (
        <div/>
    );

    let containerStyles = {
        height: '',
        width: '',
        backgroundColor: backgroundColor,
        ...style
    };

    if (Number.isInteger(size as number)) {
        containerStyles.height = `${size}rem`;
        containerStyles.width = `${size}rem`;
    }

    const containerProps = {
        className: containerClassName,
        style: containerStyles,
        onClick: onClick,
        'data-testid': testId
    };

    const contentClassName = classnames(
        classes['avatar-image'],
        classes[`avatar-${size}`],
        imageClassName
    );

    const imageElementStyle = {
        ...imageStyle,
        height: '',
        width: '',
        backgroundImage: `url("${src}")`
    };

    if (Number.isInteger(size as number)) {
        imageElementStyle.height = `${size}rem`;
        imageElementStyle.width = `${size}rem`;
    }

    const contentElement = src ? (
        <div className={contentClassName} style={imageElementStyle} />
    ) : (
        <span style={charStyle}
              className={charClassName}
        >
            {char.toUpperCase()}
        </span>
    );

    return cloneElement(
        containerElement,
        containerProps,
        contentElement
    );
};

export default withStyles<AvatarProps>(styles)(Avatar);