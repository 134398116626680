import { avatarTheme } from './base/avatar.theme';
import { buttonTheme } from './base/button.theme';
import { inputTheme } from './base/input.theme';
import { selectTheme } from './base/select.theme';
import { sliderTheme } from './base/slider.theme';
import { checkboxTheme } from './base/checkbox.theme';
import { badgeTheme } from './base/badge.theme';
import { baseFont } from './fonts';
import { paginationTheme } from './base/pagination.theme';
import { radioButtonTheme } from './base/radio.button.theme';
import { stepperTheme } from './base/stepper.theme';
import { toggleTheme } from './base/toggle.theme';
import { tabsTheme } from './base/tabs.theme';

export const baseTheme = {
    fontFamily: baseFont,
    avatar: avatarTheme,
    badge: badgeTheme,
    button: buttonTheme,
    checkbox: checkboxTheme,
    input: inputTheme,
    select: selectTheme,
    slider: sliderTheme,
    stepper: stepperTheme,
    pagination: paginationTheme,
    radioButton: radioButtonTheme,
    tabs: tabsTheme,
    toggle: toggleTheme,
    fontSize: {
        text: {
            size: '0.8rem'
        },
        h1: {
            size: '2rem',
            margin: '0.5rem 0'
        }
    }
};