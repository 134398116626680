import { colors } from '../colors';

export const stepperTheme = {
    steps: {
        normal: {
            border: colors.secondary
        },
        active: {
            border: colors.primary
        }
    }
};