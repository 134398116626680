import { colors } from '../colors';

export const paginationTheme = {
    fontSize: '0.8rem',
    colors: {
        text: colors.black,
        current: {
            background: colors.primary,
            text: colors.white
        },
        hover: {
            background: colors.primary,
            text: colors.white
        }
    }
};