import React, { Children, cloneElement, ReactElement, useState, useEffect } from 'react';

import { TabsProps } from './Tabs.types';
import withStyles from '../theme/withStyles';

const styles = theme => ({
    tabs: {
        display: 'flex',
        fontFamily: theme.fontFamily,
        borderBottom: '1px solid #eee'
    }
});

const Tabs: React.FC<TabsProps> = (props) => {
    let {
        selected,
        onChange,
        children,
        classes
    } = props;

    let [ state, setState ] = useState(selected);

    const onTabClick = (tab) => {
        setState(tab);
    };

    useEffect(() => {
        if (onChange) {
            onChange(state);
        }
    }, [ state ]);

    let tabs = Children.toArray(children).map((child: ReactElement) => {
        let extendedProps = {
            isSelected: child.props.value === state,
            onClick: onTabClick
        };
        return cloneElement(child, extendedProps);
    });

    return (
        <div className={classes.tabs}>
            {tabs}
        </div>
    );
};

export default withStyles<TabsProps>(styles)(Tabs);