import { createContext } from 'react'
import jss, { create as originalCreateJss } from 'jss'
import { createTheming, SheetsRegistry } from 'react-jss'
import preset from 'jss-preset-default'

export const ThemeContext = createContext({});
export const ThemeProviderContext = createContext({});

export const theming = createTheming(ThemeContext);

export const {ThemeProvider: JssThemeProvider, withTheme, useTheme} = theming;

export const createJss = (options = { }) =>
    originalCreateJss({
        ...preset(options),
        ...options,
        insertionPoint: 'insertion-point-jss'
    });


export const createSheetsRegistry = () => new SheetsRegistry();

export const globalJss = createJss();
export const globalSheetsRegistry = createSheetsRegistry();

export const createGenerateId = () => {
    return (rule, sheet) => `vui-${rule.key}`
};

jss.setup({createGenerateId});