const fonts = {
    BlinkMacSystemFont: 'BlinkMacSystemFont',
    AppleSystem: '-apple-system',
    SegoeUI: '\'Segoe UI\'',
    Roboto: 'Roboto',
    Oxygen: 'Oxygen',
    Ubuntu: 'Ubuntu',
    Cantarell: 'Cantarell',
    OpenSans: '\'Open Sans\'',
    HelveeticaNeue: '\'Helvetica Neue\'',
    RobotoRouble: '\'Roboto Rouble\'',
    SansSerif: 'sans-serif'
};

export const baseFont =
    `${fonts.BlinkMacSystemFont}, `
    + `${fonts.AppleSystem}, `
    + `${fonts.SegoeUI}, `
    + `${fonts.Roboto}, `
    + `${fonts.Oxygen}, `
    + `${fonts.Ubuntu}, `
    + `${fonts.Cantarell}, `
    + `${fonts.OpenSans}, `
    + `${fonts.HelveeticaNeue}, `
    + `${fonts.RobotoRouble}, `
    + `${fonts.SansSerif}`;