import React from 'react';
import classnames from 'classnames';
import { NotificationProps } from './Notification.types';
import withStyles from '../theme/withStyles';

const styles = theme => ({
    notification: {
        fontFamily: theme.fontFamily,
        padding: '0.75rem 1rem',
        borderRadius: '4px',
        backgroundColor: '#fff',
        boxShadow: '-2px 2px 10px 0 rgba(102, 116, 166, 0.2)',
        marginBottom: '0.5rem'
    },
    'notification-success': {
        backgroundColor: '#58A361',
        color: '#fff'
    },
    'notification-error': {
        backgroundColor: '#F44336',
        color: '#fff'
    }
});

const Notification: React.FC<NotificationProps> = (props) => {
    let {
        visible = true,
        success,
        error,
        children,
        classes
    } = props;

    if (!visible) {
        return null;
    }

    const className = classnames(
        classes.notification,
        { [classes['notification-success']]: success },
        { [classes['notification-error']]: error }
    );

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export default withStyles<NotificationProps>(styles)(Notification);