import React, { createElement } from 'react';
import classnames from 'classnames';

import { TypographyProps } from './Typography.types';

import './Typography.scss';
import withStyles from '../theme/withStyles';

const styles = theme => ({
    typography: {
        fontWeight: 400,
        fontFamily: theme.fontFamily
    },
    h1: {
        fontSize: '2rem',
        margin: '0.5rem 0'
    },
    h2: {
        fontSize: '1.5rem',
        margin: '0.5rem 0'
    },
    h3: {
        fontSize: '1.25rem',
        margin: '0.5rem 0'
    },
    h4: {
        fontSize: '1.2rem',
        margin: '0.5rem 0'
    },
    text: {
        fontSize: '0.8rem',
        margin: 0
    },
    description: {
        fontSize: '0.75rem',
        margin: 0
    }
});

const Typography: React.FC<TypographyProps> = (props) => {
    const {
        type,
        tagName,
        children,
        uppercase,
        className,
        classes
    } = props;

    const resultClassName = classnames(
        classes.typography,
        classes[type],
        className
    );

    const typographyProps = {
        className: resultClassName
    };

    return createElement(
        tagName,
        typographyProps,
        children
    );
};

export default withStyles<TypographyProps>(styles)(Typography);