import { baseFont } from '../fonts';
import { colors } from '../colors';

export const buttonTheme = {
    fontFamily: baseFont,
    borderRadius: '4px',
    sizes: {
        small: {
            height: '1.5rem',
            width: '1.5rem',
            fontSize: '0.75rem'
        },
        medium: {
            height: '2rem',
            width: '2rem',
            fontSize: '1rem'
        },
        large: {
            height: '2.5rem',
            width: '2.5rem',
            fontSize: '1.5rem'
        }
    },
    styles: {
        primary: {
            color: colors.primary,
            hoverColor: colors.primaryDark,
            textColor: colors.white
        },
        secondary: {
            color: colors.secondary,
            hoverColor: colors.secondaryDark,
            textColor: colors.black
        },
        outline: {
            color: colors.transparent,
            borderColor: colors.primary,
            hoverColor: colors.primaryDark,
            textColor: colors.primary,
            hoverTextColor: colors.white
        },
        ghost: {
            color: colors.transparent,
            hoverColor: colors.primaryDark,
            textColor: colors.black,
            hoverTextColor: colors.white
        },
        disabled: {
            color: colors.disabled,
            textColor: colors.disabledText
        }
    }
};