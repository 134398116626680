import React, { useState } from 'react';
import classnames from 'classnames';

import { CheckboxProps } from './Checkbox.types';
import withStyles from '../theme/withStyles';
import CheckIcon from './check.icon';

const styles = theme => ({
    'checkbox-container': {
        fontFamily: theme.fontFamily,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        userSelect: 'none',
        '& input': {
            position: 'absolute',
            cursor: 'pointer',
            opacity: 0,
            height: 0,
            width: 0
        },
        '& input:checked ~ .vui-checkbox-mark': {
            '& .vui-checkbox-mark-icon': {
                display: 'inline-block'
            }
        },
        '& label': {
            margin: 0,
            fontSize: theme.fontSize.text.size,
            cursor: 'pointer'
        },
        '&:hover > .vui-checkbox-mark': {
            border: `1px solid ${theme.checkbox.colors.primary.border}`,
            color: theme.checkbox.colors.primary.border
        }
    },
    'checkbox-mark': {
        cursor: 'pointer',
        height: '1rem',
        width: '1rem',
        boxSizing: 'border-box',
        border: `1px solid #eee`,
        borderRadius: '4px',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& input:checked ~ .vui-checkbox-mark-icon': {
            display: 'block'
        },
        '&:hover': {
            border: `1px solid ${theme.checkbox.colors.primary.border}`,
            color: theme.checkbox.colors.primary.border
        }
    },
    'checkbox-mark-icon': {
        display: 'none'
    }
});

const Checkbox: React.FC<CheckboxProps> = (props) => {
    const {
        id,
        checked = false,
        name,
        label,
        className,
        onChange,
        classes
    } = props;

    let [ state, setState ] = useState(checked);

    const onValueChange = (e) => {
        setState(e.target.checked);

        if (onChange) {
            onChange(id, e.target.checked);
        }
    };

    return (
        <label htmlFor={id} className={classnames(className, classes['checkbox-container'])}>
            <input
                id={id}
                type="checkbox"
                checked={state}
                name={name}
                onChange={onValueChange}
            />

            <div className={classes['checkbox-mark']}>
                <CheckIcon className={classes['checkbox-mark-icon']}/>
            </div>

            <label htmlFor={id}>{label}</label>
        </label>
    );
};

export default withStyles<CheckboxProps>(styles, { name: 'Input', input: true })(Checkbox);