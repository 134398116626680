import React, { Children, cloneElement, ReactElement } from 'react';

import withStyles from '../theme/withStyles';

import { RadioGroupProps } from './RadioGroup.types';

const styles = theme => ({
    'radio-group': {
        fontFamily: theme.fontFamily,
        border: 'none',
        marginInlineStart: 0,
        marginInlineEnd: 0,
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        paddingBlockStart: 0,
        paddingBlockEnd: 0,
        '& label': {
            marginBottom: '0.5rem'
        },
        '& p': {
            margin: '0 0 0.5rem 0',
            fontSize: theme.fontSize.text.size
        }
    }
});

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
    let  {
        label,
        name,
        value,
        children,
        classes
    } = props;

    let onValueChange = (e) => {
        console.log(e.target.id);
    };

    let radios = Children.toArray(children).map((child: ReactElement) => {
        let extendedProps = {
            checked: child.props.value === value,
            name: name
        };

        return cloneElement(child, extendedProps);
    });

    return (
        <fieldset className={classes['radio-group']}
                  onChange={onValueChange}
        >
            <p className={classes['radio-group-label']}>{label}</p>

            {radios}
        </fieldset>
    );
};

export default withStyles<RadioGroupProps>(styles)(RadioGroup);