import React from 'react';

import withStyles from '../theme/withStyles';

import { RadioButtonProps } from './RadioButton.types';

const styles = theme => ({
    'radio-button-container': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        userSelect: 'none',
        fontFamily: theme.fontFamily,
        fontSize: theme.fontSize.text.size,
        '& input': {
            position: 'absolute',
            opacity: 0,
            height: 0,
            width: 0,
            cursor: 'pointer'
        },
        '& input:checked ~ .vui-radio-button-mark': {
            '&::after': {
                height: '0.5rem',
                width: '0.5rem',
                borderRadius: '50%',
                backgroundColor: theme.radioButton.colors.regular.border,
                content: '""'
            }
        },
        '&:hover input:checked ~ .vui-radio-button-mark': {
            '&::after': {
                backgroundColor: theme.radioButton.colors.hover.border,
            }
        },
        '&:hover .vui-radio-button-mark': {
            border: `1px solid ${theme.radioButton.colors.hover.border}`
        },
    },
    'radio-button-mark': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderRadius: '50%',
        cursor: 'pointer',
        border: `1px solid ${theme.radioButton.colors.regular.border}`,
        height: '1rem',
        width: '1rem',
        marginRight: '0.5rem',
        '&:hover': {
            border: `1px solid ${theme.radioButton.colors.hover.border}`
        }
    }
});

const RadioButton: React.FC<RadioButtonProps> = (props) => {
    let {
        value,
        checked = false,
        label,
        name,
        onChange,
        classes
    } = props;


    const onValueChange = () => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <label htmlFor={value}
               className={classes['radio-button-container']}
        >
            <input id={value}
                   type="radio"
                   value={checked.toString()}
                   name={name}
                   onChange={onValueChange}
            />

            <span className={classes['radio-button-mark']}/>

            <span>{label}</span>
        </label>
    );
};

export default withStyles<RadioButtonProps>(styles)(RadioButton);