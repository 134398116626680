import React, { useContext } from 'react';
import { createUseStyles, JssProvider, ThemeProvider, useTheme } from 'react-jss';

import { baseTheme } from './baseTheme';
import { createGenerateId, globalJss, globalSheetsRegistry, ThemeProviderContext } from './jss';

const generateId = createGenerateId();

const withBaseTheme = <T extends unknown> (Component) => {
    return (function (props: T) {
        const context = useContext(ThemeProviderContext);
        //@ts-ignore
        if (context.jss) return <Component {...props} />;

        return (
            <JssProvider jss={globalJss}
                         registry={globalSheetsRegistry}
                         generateId={generateId}
            >
                <ThemeProvider theme={baseTheme}>
                    <Component {...props} />
                </ThemeProvider>
            </JssProvider>
        )
    });
};

const withStyles = <T extends unknown> (styles, options: any = {}) => {
    return (Component) => {
        const useStyles = createUseStyles(styles);
        let styledComponent: any = withBaseTheme<T>(
            ((props: T) => {
                const theme = useTheme();
                const classes = useStyles(theme);
                return (
                    <Component {...props} classes={classes} />
                )
            })
        );

        if (options.name) styledComponent.displayName = options.name;
        styledComponent.input = options.input;

        return styledComponent;
    }
};

export default withStyles;