import { colors } from '../colors';

export const avatarTheme = {
    borderColor: colors.secondary,
    colors: {
        border: colors.secondary,
        char: colors.white,
        transparent: {
            char: colors.black
        }
    },
    sizes: {
        small: '2rem',
        medium: '2.5rem',
        large: '3rem'
    }
};