import React, { Children, cloneElement, ReactElement, useState } from 'react';

import withStyles from '../theme/withStyles';

import { CheckboxGroupProps } from './CheckboxGroup.types';

const styles = theme => ({
    'checkbox-group': {
        fontFamily: theme.fontFamily,
        border: 'none',
        marginInlineStart: 0,
        marginInlineEnd: 0,
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        paddingBlockStart: 0,
        paddingBlockEnd: 0,
        '& p': {
            margin: '0 0 0.5rem 0',
            fontSize: theme.fontSize.text.size
        },
        '& > label': {
            marginBottom: '0.5rem'
        }
    },
    'checkbox-item': {
        marginBottom: '0.5rem'
    }
});

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
    let {
        id,
        label,
        value = [],
        getInitialValue,
        onChange,
        children,
        classes
    } = props;

    const groupId = id;

    const getValue = (id: string) => {
        let value = [];
        if (getInitialValue) {
            value = getInitialValue(id) || [];
        }

        return value;
    };

    let [ state, setState ] = useState(getValue(id));

    const onValueChange = (id, value) => {
        if (value) {
            state.push(id);
        } else {
            state.splice(state.indexOf(id), 1);
        }

        setState(state);

        if (onChange) {
            onChange(groupId, state);
        }
    };

    const extendCheckboxesProps = (children: any) => {
        return Children.toArray(children).map((child: ReactElement) => {
                if (child.props?.id) {
                    let extendedProps = {
                        name: id,
                        checked: state.indexOf(child.props.id) > -1,
                        onChange: onValueChange
                    };
                    return cloneElement(child, extendedProps);
                }

                if (child.props?.children && child.props.children.length > 0) {
                    let children = extendCheckboxesProps(child.props.children);
                    return cloneElement(child, {}, children);
                }

                return child;
            }
        );
    };

    /*let checkboxes = Children.toArray(children).map((child: ReactElement) => {
        const extendedProps = {
            name: id,
            checked: state.indexOf(child.props.id) > -1,
            onChange: onValueChange
        };

        return cloneElement(child, extendedProps);
    });*/

    let checkboxes = extendCheckboxesProps(children);

    return (
        <fieldset name={id}
                  className={classes['checkbox-group']}
        >
            <p>{label}</p>

            {checkboxes}
        </fieldset>
    );
};

export default withStyles<CheckboxGroupProps>(styles)(CheckboxGroup);