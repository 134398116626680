import React, { cloneElement, useEffect, useState } from 'react';
import classnames from 'classnames';

import withStyles from '../theme/withStyles';

import { PaginationProps } from './Pagination.types';

const styles = theme => ({
    pagination: {
        display: 'flex',
        fontFamily: theme.fontFamily,
        fontSize: theme.pagination.fontSize,

    },
    'pagination-page': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '1rem',
        minWidth: '1rem',
        margin: '0 0.25rem',
        padding: '0.25rem',
        cursor: 'pointer',
        textDecoration: 'none',
        color: theme.pagination.colors.text,
        '&:hover': {
            backgroundColor: theme.pagination.colors.hover.background,
            color: theme.pagination.colors.hover.text,
            borderRadius: '9999px'
        }
    },
    'pagination-current-page': {
        cursor: 'default',
        backgroundColor: theme.pagination.colors.current.background,
        color: theme.pagination.colors.current.text,
        borderRadius: '9999px'
    }
});

const Pagination: React.FC<PaginationProps> = (props) => {
    let {
        currentPage = 1,
        pagesCount,
        onChange,
        pageContainer,
        classes
    } = props;

    const getDisplayingPages = (currentPage) => {
        let displayedPages = [];

        if (pagesCount <= 10) {
            for (let i = 1; i <= pagesCount; ++i) {
                displayedPages.push(i);
            }

            return displayedPages;
        }

        let startPage = currentPage;
        if (currentPage < 5) {
           startPage = 1;
        }
        if (currentPage >= 5) {
            startPage -= 2;
        }
        if (currentPage > pagesCount - 4) {
            startPage = pagesCount - 4
        }

        for (let i = startPage; i < startPage + 5; ++i) {
            displayedPages.push(i);
        }

        return displayedPages;
    };

    let [ state, setState ] = useState({
        current: currentPage,
        displayedPages: getDisplayingPages(currentPage)
    });

    useEffect(() => {
        setState({
            ...state,
            current: currentPage
        })
    }, [ currentPage, setState ]);

    const onClick = (page) => {
        setState({
            current: page,
            displayedPages: getDisplayingPages(page)
        });
        if (onChange) {
            onChange(page);
        }
    };

    const PageElement = ({ pageNumber }) => {
        let container = pageContainer ? (
            pageContainer(pageNumber)
        ) : (
            <div onClick={() => onClick(pageNumber)}/>
        );

        let containerProps = {
            className:
                classnames(
                    classes[ 'pagination-page' ],
                    { [ classes[ 'pagination-current-page' ] ]: pageNumber === state.current }
                )
        };

        return cloneElement(
            container,
            containerProps,
            pageNumber
        )
    };

    return (
        <div className={classes.pagination}>
            {pagesCount > 10 && state.current >= 5 && <PageElement pageNumber={1}/>}

            {pagesCount > 10 && state.current >= 5 && <span>...</span>}

            {
                state.displayedPages.map(page => (
                    <PageElement key={page} pageNumber={page}/>
                ))
            }

            {pagesCount > 10 && state.current < pagesCount - 3 && <span>...</span>}

            {(pagesCount > 10 && state.current < pagesCount - 3) && <PageElement pageNumber={pagesCount}/>}
        </div>
    );
};

export default withStyles<PaginationProps>(styles)(Pagination);