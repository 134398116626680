import React from 'react';
import { PopupProps } from './Popup.types';
import withStyles from '../theme/withStyles';

const styles = theme => ({
    popup: {
        position: 'absolute',
        zIndex: 1000,
        padding: '2rem',
        borderRadius: '4px',
        backgroundColor: '#eee',
        fontFamily: theme.fontFamily
    }
});

const Popup: React.FC<PopupProps> = (props) => {
    let {
        visible,
        children,
        classes
    } = props;

    if (!visible) {
        return null;
    }

    return (
        <div className={classes.popup}>
            {children}
        </div>
    );
};

export default withStyles<PopupProps>(styles)(Popup);