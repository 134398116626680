import { colors } from '../colors';

export const radioButtonTheme = {
    colors: {
        regular: {
            border: colors.black
        },
        hover: {
            border: colors.primary
        }
    }
};