import React, { useEffect, useReducer } from 'react';
import NotificationsContext from './NotificationsContext';
import NotificationsContainer from './NotificationsContainer';

const NotificationsReducer = (state, action) => {
    switch (action.type) {
        case 'show': {
            state.notifications.splice(0, 0, {
                added: new Date(),
                element: action.payload
            });
            return {
                ...state
            }
        }
        case 'clear': {
            let index = state.notifications.indexOf(action.payload);
            if (index > -1) {
                state.notifications.splice(index, 1);
            }

            return {
                ...state
            }
        }
        default:
            return state;
    }
};

const NotificationsContextProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(NotificationsReducer, {
        notifications: []
    });

    useEffect(() => {
        let timer;

        if (state.notifications.length > 0) {
            timer = setInterval(() => {
                state.notifications.forEach(notification => {
                    let age = new Date().getTime() - notification.added.getTime();
                    if (age > 5000) {
                        dispatch({ type: 'clear', payload: notification });
                    }
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [ state ]);

    const showNotification = (notification) => {
        dispatch({ type: 'show', payload: notification });
    };

    return (
        <NotificationsContext.Provider value={{
            showNotification: showNotification
        }}>
            {children}

            <NotificationsContainer notifications={state.notifications}/>
        </NotificationsContext.Provider>
    );
};

export default NotificationsContextProvider;