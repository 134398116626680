import React, { cloneElement, isValidElement } from 'react';
import classnames from 'classnames';

import withStyles from '../theme/withStyles';

import { ButtonProps } from './Button.types';

const styles = theme => ({
    'button': {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        outline: 'none',
        padding: '0 1.25rem',
        cursor: 'pointer',
        fontSize: '1em',
        fontFamily: theme.fontFamily,
        '&:active': {
            transform: 'translateY(1px)',
            filter: 'saturate(150%)'
        },
        '&:disabled': {
            cursor: 'default',
            backgroundColor: theme.button.styles.disabled.color,
            color: theme.button.styles.disabled.textColor,
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: theme.button.styles.disabled.color,
                color: theme.button.styles.disabled.textColor,
                border: 'none',
                boxShadow: 'none'
            },
            '&:active': {
                transform: 'translateY(0)',
                filter: 'saturate(0%)'
            }
        }
    },
    'button-content': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    'button-s': {
        minHeight: theme.button.sizes.small.height,
        minWidth: theme.button.sizes.small.width,
        fontSize: theme.button.sizes.small.fontSize
    },
    'button-m': {
        minHeight: theme.button.sizes.medium.height,
        minWidth: theme.button.sizes.medium.width,
        fontSize: theme.button.sizes.medium.fontSize
    },
    'button-l': {
        minHeight: theme.button.sizes.large.height,
        minWidth: theme.button.sizes.large.width,
        fontSize: theme.button.sizes.large.fontSize
    },
    'button-regular': {
        borderRadius: '4px'
    },
    'button-rounded': {
        borderRadius: '9999px'
    },
    'button-square': {
        borderRadius: 0
    },
    'button-primary': {
        backgroundColor: theme.button.styles.primary.color,
        color: theme.button.styles.primary.textColor,
        '&:hover': {
            backgroundColor: theme.button.styles.primary.hoverColor
        }
    },
    'button-secondary': {
        backgroundColor: theme.button.styles.secondary.color,
        color: theme.button.styles.secondary.textColor,
        '&:hover': {
            backgroundColor: theme.button.styles.secondary.hoverColor
        }
    },
    'button-outline': {
        backgroundColor: theme.button.styles.outline.color,
        color: theme.button.styles.outline.textColor,
        boxShadow: `0px 0px 0px 1px ${theme.button.styles.outline.borderColor}`,
        '&:hover': {
            backgroundColor: theme.button.styles.outline.hoverColor,
            boxShadow: 'none',
            color: theme.button.styles.outline.hoverTextColor
        }
    },
    'button-ghost': {
        backgroundColor: theme.button.styles.ghost.color,
        color: theme.button.styles.ghost.textColor,
        '&:hover': {
            backgroundColor: theme.button.styles.ghost.hoverColor,
            color: theme.button.styles.ghost.hoverTextColor
        }
    },
    'button-icon': {
        height: '0.8rem',
        width: '0.8rem'
    },
    'button-icon-left': {
        marginRight: '0.4rem'
    },
    'button-icon-right': {
        marginLeft: '0.4rem'
    }
});

const Button: React.FC<ButtonProps> = (props) => {
    let {
        type = 'button',
        size = 'm',
        styleType = 'primary',
        shape = 'regular',
        onClick,
        disabled,
        href,
        target,
        rel,
        icon,
        iconPosition,
        container,
        className,
        style,
        testId,
        classes,
        children
    } = props;

    const resultClass = classnames(
        className,
        classes['button'],
        classes[`button-${size}`],
        classes[`button-${shape}`],
        classes[`button-${styleType}`]
    );

    const iconLeft = iconPosition === 'left';

    const renderIcon = (icon) => {
        if (icon) {
            const iconProps = {
                color: null
            };
            const resultClassName = classnames(
                classes['button-icon'],
                { [classes['button-icon-left']]: iconLeft },
                { [classes['button-icon-right']]: !iconLeft }
            );
            const resultProps = { ...iconProps, className: resultClassName };
            return cloneElement(icon, resultProps);
        }
    };

    const iconEl = renderIcon(icon);

    const resultChildren = (
        <span className={classes['button-content']}>
            {iconLeft && iconEl}
            {children}
            {!iconLeft && iconEl}
        </span>
    );

    const resultContainer = isValidElement(container) ? (
        container
    ) : href ? (
        <a href={href} target={target} rel={rel} />
    ) : (
        <button type={type}/>
    );

    const resultProps = {
        className: resultClass,
        style: style,
        disabled: disabled,
        onClick: onClick,
        'data-testid': testId
    };

    return cloneElement(
        resultContainer,
        resultProps,
        resultChildren
    );
};

export default withStyles<ButtonProps>(styles)(Button);